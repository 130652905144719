import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import axios from "axios";
import setAuthHeaders from "./function/setAuthToken";
import VueSocialSharing from "vue-social-sharing";

if (localStorage.token) {
  setAuthHeaders(localStorage.token);
} else {
  setAuthHeaders(false);
}

Vue.config.productionTip = false;

new Vue({
  vuetify,
  VueSocialSharing,
  axios,
  router,
  render: (h) => h(App),
}).$mount("#app");
